<template>
  <ul class="cards">
  <li>
    <a href="" class="card">
      <img src="../assets/img/shiguoyu.png" class="card__image" alt="" />
      <div class="card__overlay">
        <div class="card__header">
          <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                     
          <img class="card__thumb" src="../assets/img/owl-icon.png" alt="" />
          <div class="card__header-text">
            <h3 class="card__title">石锅鱼</h3>            
            <span class="card__status">Feb-01-2023</span>
          </div>
        </div>
        <p class="card__description">鳄不出所料又吃撑了。</p>
      </div>
    </a>      
  </li>
  <li>
    <a href="" class="card">
      <img src="../assets/img/qishansaozimian.png" class="card__image" alt="" />
      <div class="card__overlay">
        <div class="card__header">
          <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                     
          <img class="card__thumb" src="../assets/img/owl-icon.png" alt="" />
          <div class="card__header-text">
            <h3 class="card__title">岐山臊子面</h3>            
            <span class="card__status">Jan-31-2023</span>
          </div>
        </div>
        <p class="card__description">鳄不出所料又吃撑了。</p>
      </div>
    </a>      
  </li>
  <li>
    <a href="" class="card">
      <img src="https://i.imgur.com/2DhmtJ4.jpg" class="card__image" alt="" />
      <div class="card__overlay">        
        <div class="card__header">
          <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>                 
          <img class="card__thumb" src="https://i.imgur.com/sjLMNDM.png" alt="" />
          <div class="card__header-text">
            <h3 class="card__title">kim Cattrall</h3>
            <span class="card__status">3 hours ago</span>
          </div>
        </div>
        <p class="card__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?</p>
      </div>
    </a>
  </li>
  
  
</ul>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
